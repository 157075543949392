import { paramsQueryByRelationId, paramsQueryFeedsByName } from './helpers';
import {
  IBody,
  IEdit,
  IParams,
  IParamsByName,
  IParamsByRelationId,
  IResponseCreate,
  IResponseFeedElemetTypes,
  IResponseFeedTypes,
  IResponseGet,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

enum NamesEnum {
  AUTHORS = 'authors',
  TRANSLATORS = 'translators',
  PERSONS = 'persons',
  READERS = 'readers',
  CLIPS = 'clips',
  PLAYLISTS = 'playlists',
  CHANNELS = 'channels',
  RUBRICS = 'rubrics',
  OWNERS = 'owners',
  BOOK_SERIES = 'book_series',
  SUPERMIXES = 'supermixes',
  COMPILATIONS = 'compilations',
}

const namesMapping: Record<string, NamesEnum> = {
  Авторы: NamesEnum.AUTHORS,
  Переводчики: NamesEnum.TRANSLATORS,
  Персоны: NamesEnum.PERSONS,
  Чтецы: NamesEnum.READERS,
  Клипы: NamesEnum.CLIPS,
  Плейлисты: NamesEnum.PLAYLISTS,
  Каналы: NamesEnum.CHANNELS,
  Рубрики: NamesEnum.RUBRICS,
  Правообладатель: NamesEnum.OWNERS,
  'Книжная серия': NamesEnum.BOOK_SERIES,
  Супермикс: NamesEnum.SUPERMIXES,
  Подборки: NamesEnum.COMPILATIONS,
};

export const feedsApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeed: builder.mutation<IResponseCreate, IBody>({
      query: (body) => ({
        url: endpoints.v2_feed,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.v2_feed],
    }),

    editFeed: builder.mutation({
      query: (body: IEdit) => ({
        url: endpoints.v2_feed,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [tags.v2_feed],
    }),

    deleteFeed: builder.mutation({
      query: (id) => ({
        url: `${endpoints.v2_feed}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: tags.v2_feed, id: arg },
      ],
    }),
  }),
});

export const feedsApiByQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedByCondition: builder.query<IResponseGet, IParams>({
      query: ({ limit, offset, is_related }) => ({
        url: endpoints.v2_feeds,
        params: { limit, offset, is_related },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { is_related } = queryArgs;

        return is_related;
      },
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.v2_feed, id } as const)),
            { type: tags.v2_feed, id: 'FEEDS_LIST' },
          ];
        } else {
          return [{ type: tags.v2_feed, id: 'FEEDS_LIST' }];
        }
      },
    }),
    getFeedsByName: builder.query<IResponseGet, IParamsByName>({
      query: ({ name, relation_id }) => ({
        url: `${endpoints.v2_feedsFind}?${paramsQueryFeedsByName(
          name,
          relation_id
        )}`,
      }),
      providesTags: [tags.v2_feed],
    }),
    getFeedById: builder.query<any, number>({
      query: (id) => ({ url: `${endpoints.v2_feed}?id=${id}` }),
      transformResponse: (response: any) => ({
        response: {
          id: response.response.id,
          uuid: response.response.uuid,
          created_at: response.response.created_at,
          update_at: response.response.update_at,
          name: response.response.name,
          weight: response.response.weight,
          is_active: response.response.is_active,
          screen: response.response.screen,
          elements_count: response.response.elements_count,
          feed_type: response.response.feed_type,
          elements: Object.values(
            response.response.feed_elements.reduce((acc, currentElement) => {
              const { frame_position, element_type } = currentElement;

              if (!acc[frame_position]) {
                acc[frame_position] = {
                  id: frame_position + 1,
                  frame_position,
                  count: 0,
                  entity_type_for_query: element_type,
                  element_array: [],
                };
              }

              const updatedElement = {
                ...currentElement,
                id: currentElement.element_id,
              };
              delete updatedElement.element_id;

              acc[frame_position].element_array.push(updatedElement);
              acc[frame_position].count++;

              return acc;
            }, {})
          ),
          message: 'OK',
          statusCode: 200,
          totalCount: 1,
          timestamp: new Date().toISOString(),
        },
      }),
      providesTags: [tags.v2_feed],
    }),
  }),
});

export const feedsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedType: builder.query<IResponseFeedTypes, void>({
      query: () => ({
        url: endpoints.v2_feedTypes,
      }),
      transformResponse: (response: IResponseFeedTypes) => ({
        response: response.response.map((item, index) => ({
          id: index + 1,
          uuid: `uuid-${index}-eq23-${index}`,
          name: item as never,
        })),
        message: 'OK',
        statusCode: 200,
        totalCount: 1,
        timestamp: new Date().toISOString(),
      }),
      providesTags: [tags.v2_feed],
    }),

    getFeedElementType: builder.query<IResponseFeedElemetTypes, void>({
      query: () => ({
        url: endpoints.v2_feedsElementTypes,
      }),
      transformResponse: (response: IResponseFeedElemetTypes) => ({
        response: response.response.map((item, index) => ({
          id: index + 1,
          uuid: `uuid-${index}-eq23-${index}`,
          name: namesMapping[item as never],
        })),
        message: 'OK',
        statusCode: 200,
        totalCount: 1,
        timestamp: new Date().toISOString(),
      }),
      providesTags: [tags.v2_feed],
    }),

    getFeedRelationType: builder.query<IResponseFeedElemetTypes, void>({
      query: () => ({
        url: endpoints.feedRelationType,
      }),
      providesTags: [tags.v2_feed],
    }),

    getFeedByRelationId: builder.query<IResponseGet, IParamsByRelationId>({
      query: ({ id, is_shown_in_related_block }) => ({
        url: `${endpoints.feedGetByRelationId}?${paramsQueryByRelationId(
          id,
          is_shown_in_related_block
        )}`,
      }),
      providesTags: [tags.v2_feed],
    }),
  }),
});

export const {
  useCreateFeedMutation,
  useDeleteFeedMutation,
  useEditFeedMutation,
} = feedsApiMutation;

export const {
  useGetFeedElementTypeQuery,
  useGetFeedTypeQuery,
  useGetFeedByRelationIdQuery,
} = feedsApiQuery;

export const {
  useGetFeedByIdQuery,
  useGetFeedByConditionQuery,
  useGetFeedsByNameQuery,
} = feedsApiByQuery;
