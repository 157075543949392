import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors: {},
};

const feedErrors = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setFeedErrors(state, action) {
      state.errors = action.payload;
    },
    clearError(state, action) {
      const field = action.payload;
      if (field in state.errors) {
        delete state.errors[field];
      }
    },
  },
});

export const { setFeedErrors, clearError } = feedErrors.actions;
export const feedErrorsReducer = feedErrors.reducer;
