import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEntityMerging } from '../../../app/global/types';

const initialState = {
  entity: null,
  entities: {
    response: [],
    totalCount: null,
  },
};

export const searchSlice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {
    setSearchSlice(state, action: PayloadAction<any>) {
      state.entity = action.payload;
    },
    serRandomEntities(
      state,
      action: PayloadAction<{ response: IEntityMerging[]; totalCount: number }>
    ) {
      state.entities = action.payload;
    },
  },
});

export const { setSearchSlice, serRandomEntities } = searchSlice.actions;
export const setSearchSliceReducer = searchSlice.reducer;
