export const endpoints = {
  //auth
  login: '/api/main/auth/login',
  register: '/api/main/auth/register',
  profile: '/api/main/profile',
  user: '/api/main/auth/me',
  googleLogin: '/api/main/auth/google/token',

  //upload
  imageUpload: '/api/main/image/upload',
  videoUpload: '/api/main/video',
  documentUpload: '/api/main/document',
  archiveUpload: '/api/main/archive',
  audioUpload: '/api/main/audio',

  //author
  authorCreate: '/api/main/author/create',
  author: '/api/main/author',
  authorGet: '/api/main/authors',
  authorGetByName: '/api/main/authors/find',

  //counterparties
  counterpartiesCreate: '/api/main/counterparty/create',
  counterparties: '/api/main/counterparty',
  counterpartiesGet: '/api/main/counterparties',
  counterpartiesGetByName: '/api/main/counterparties/find',

  //translator
  translatorCreate: '/api/main/translator/create',
  translator: '/api/main/translator',
  translatorGet: '/api/main/translators',
  translatorGetByName: '/api/main/translators/find',

  //owner
  ownerCreate: '/api/main/owner/create',
  owner: '/api/main/owner',
  ownerGet: '/api/main/owners',
  ownerGetByName: '/api/main/owners/find',
  ownerTypes: '/api/main/owners/types',
  ownerLevels: '/api/main/owners/levels',

  //person
  personCreate: '/api/main/person/create',
  person: '/api/main/person',
  personGet: '/api/main/persons',
  personGetByName: '/api/main/persons/find',

  //reader
  readerCreate: '/api/main/reader/create',
  reader: '/api/main/reader',
  readerGet: '/api/main/readers',
  readerGetByName: '/api/main/readers/find',

  //playlist
  playlistCreate: '/api/main/playlist/create',
  playlist: '/api/main/playlist',
  playlistGet: '/api/main/playlists',
  playlistGetByName: '/api/main/playlists/find',

  //content-type
  content_type: 'api/main/content-types',

  //channel
  channelCreate: '/api/main/channel/create',
  channel: '/api/main/channel',
  channelPlaylistPosition: '/api/main/playlists/channel/position',
  channelGet: '/api/main/channels',
  channelGetByName: '/api/main/channels/find',

  //feed
  // feedCreate: '/api/main/feed/create',
  // feed: '/api/main/feed',
  // feedGet: '/api/main/feeds',
  // feedGetByName: '/api/main/feeds/find',
  // feedType: '/api/main/feed/types',
  // feedElementType: '/api/main/feed/element/type',
  feedRelationType: '/api/main/feed/relation/types',
  // feedRegistry: '/api/main/feed/registry',
  feedGetByRelationId: '/api/main/feeds/by-relation',

  //v2_feed
  v2_feed: '/api/main/feed-v2/feed',
  v2_feeds: '/api/main/feed-v2/feeds',
  v2_feedsLow: '/api/main/feed-v2/feeds/low',
  v2_feedsFind: '/api/main/feed-v2/feeds/find',
  v2_feedTypes: '/api/main/feed-v2/feed/types',
  v2_feedsPages: '/api/main/feed-v2/feeds/pages',
  v2_feedsElements: '/api/main/feed-v2/feeds/elements',
  v2_feedsElementTypes: '/api/main/feed-v2/feed/element/types',

  //relatedFeed
  relatedFeedsGet: '/api/main/related-feeds',
  relatedFeed: '/api/main/related-feed',
  relatedFeedElements: '/api/main/related-feed/elements',
  algorithms: '/api/main/algorithms',
  algorithmScreens: '/api/main/algorithms/screens',
  relatedFeedByCondition: '/api/main/related-feeds',
  relatedFeedsPositions: '/api/main/related-feeds/positions',

  //feedGroup
  feedGroupCreate: '/api/main/feed-group/create',
  feedGroup: '/api/main/feed-group',
  feedGroupGet: '/api/main/feed-groups',
  feedGroupGetByName: '/api/main/feed-groups/find',

  //price
  price: '/api/main/price',
  priceGet: '/api/main/prices',
  currency: '/api/main/currency',
  currencyGet: '/api/main/currencies',

  //rate
  rateCreate: 'api/main/rate/create',
  rate: 'api/main/rate',
  rateGet: 'api/main/rates',
  rateGetPlaylists: 'api/main/rate/playlists',
  rateGetPeriods: 'api/main/periods',

  //rubric
  rubricCreate: '/api/main/rubric/create',
  rubric: '/api/main/rubric',
  rubricGet: '/api/main/rubrics',
  rubricGetByName: '/api/main/rubrics/find',

  //clip
  clipCreate: '/api/main/clip/create',
  clip: '/api/main/clip',
  clipGet: 'api/main/clips',
  clipGetByName: 'api/main/clips/find',

  //content
  contentStatus: '/api/main/content/status',

  //collection
  collectionCreate: '/api/main/collection/create',
  collection: '/api/main/collection',
  collectionGet: '/api/main/collections',
  collectionGetByType: '/api/main/collections/by-type',
  collectionTypes: '/api/main/collection/types',
  collectionGetByName: '/api/main/collections/find',
  collectionElements: '/api/main/collection/elements',

  //filters
  filters: '/api/main/filters',
  filtersReference: '/api/main/filters/ref',

  //massActions
  massActions: '/api/main/mass-actions',

  //topic catalog
  topicsGroups: '/api/main/catalog/topic-groups',
  topics: '/api/main/catalog/topics',
  topic: '/api/main/catalog/topic',
  topicsStats: '/api/main/catalog/topics/stat',

  //tag catalog
  tagGroups: '/api/main/catalog/tag-groups',
  tags: '/api/main/catalog/tags',
  tag: '/api/main/catalog/tag',
  tagAncestors: '/api/main/catalog/tag/ancestors',

  //book series
  bookSeriesGet: 'api/main/book-series/many',
  bookSeries: 'api/main/book-series',

  //user-actions
  getStatsByOwner: '/api/main/statistics/reports/owner',
  getStatsBySupermix: '/api/main/statistics/reports/supermix',
  getSupermixDetails: '/api/main/statistics/reports/supermix/details',
  getSupermixFilters: '/api/main/statistics/reports/supermix/details/filters',

  getTotalReport: '/api/main/statistics/reports/total',
  getSummaryReport: '/api/main/statistics/reports/each',
  getTBankReport: '/api/main/statistics/reports/tbank',
  getAppleBankReport: '/api/main/statistics/reports/app-store',
  getLicenseReport: '/api/main/statistics/reports/license',
  //rss
  rss: '/api/main/rss',

  //contracts
  contract: '/api/main/contract',
  contractGetByCondition: '/api/main/contracts',

  // keywords
  keyword: '/api/main/keyword',
  kewordsByCondition: '/api/main/keywords',

  // supermix
  supermix: '/api/main/supermix',
  supermixes: '/api/main/supermixes',
  supermixPlaylists: '/api/main/supermix/playlists',
  supermixesByPlaylistId: '/api/main/supermixes/playlist',

  // questions
  question: '/api/main/question',
  questions: '/api/main/questions',
  questionsNoThemes: '/api/main/questions/no-themes',
  questionsPosition: '/api/main/question/position',

  //topicQuestions

  topicsQuestions: '/api/main/themes',
  topicQuestions: '/api/main/theme',
  topicQuestionsPosition: '/api/main/theme/position',

  // restrictions
  restrictions: '/api/main/restrictions/entity',

  //sections
  sections: '/api/main/sections',
  getSectionLists: '/api/main/sections/lists',

  infoSection: '/api/main/sections/info',
  autoSection: '/api/main/sections/auto',
  sectionPosition: '/api/main/sections/position',
  baseSection: '/api/main/sections/base',

  //compilations
  compilation: '/api/main/compilation',
  compilations: '/api/main/compilations',
  compilationPlaylists: '/api/main/compilation/playlists',
  compilationsPlaylist: '/api/main/compilations/playlist',

  //tag for compilations
  tagForCompilationsGroups: '/api/main/compilations/tag-groups',
  tagsForCompilations: '/api/main/compilations/tags',
  tagForCompilationsAncestors: '/api/main/compilations/tag/ancestors',
  tagForCompilation: '/api/main/compilations/tag',

  //maintenance
  maintenance: '/maintenance',
};
