import cn from 'classnames';
import { useEffect, useState } from 'react';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { IEntityMerging } from '../../../app/global/types';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { KitItem } from '../../KitItem';
import { ObligatoryField } from '../../ObligatoryField';

import style from './AccessoriesKit.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  count: number;
  options: Array<IEntityMerging>;
  items: IEntityMerging[];
  elementType: IElementType;
  onChange?: (elements: (IEntityMerging | undefined)[]) => void;
  label?: string;
  isObligatory?: boolean;
  isError?: boolean;
  withPagination?: boolean;
  loadOptions: LoadOptions<
    { name: string },
    GroupBase<{ name: string }>,
    { offset?: number; offsetSearch?: number }
  >;
  additional?: { offset?: number; offsetSearch?: number };
}

export interface IElementType {
  id: number;
  name: string;
}

export const AccessoriesKit = ({
  count,
  options,
  elementType,
  items,
  searchEntity,
  label = 'Набор элементов *',
  isObligatory,
  isError = false,
  withPagination,
  loadOptions,
  additional,
  onChange,
}: IProps): JSX.Element => {
  const [elements, setElements] = useState<IEntityMerging[]>([]);

  // Синхронизация состояния с входящими props
  useEffect(() => {
    const initialElements = items.length
      ? [...items]
      : [...Array(count).fill(undefined)];
    setElements(initialElements);
  }, [items, count]);

  // Корректируем количество элементов в зависимости от count
  const adjustedElements =
    elements.length < count
      ? [...elements, ...Array(count - elements.length).fill(undefined)]
      : elements.slice(0, count);

  const clickHandle = (option: IEntityMerging, selectedIndex: number) => {
    const updatedElements = adjustedElements.map((el, idx) =>
      idx === selectedIndex ? option : el
    );
    setElements(updatedElements);
    onChange?.(updatedElements); // Вызываем callback, если он передан
  };

  return (
    <div className={style.container}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {label} {isObligatory && <ObligatoryField />}
      </h3>
      <div className={style.wrapper}>
        {adjustedElements.map((element, index) => (
          <KitItem
            key={element?.id || index}
            searchEntity={searchEntity}
            options={options}
            elements={adjustedElements}
            item={element}
            withPagination={withPagination}
            onClick={(option) => clickHandle(option, index)}
            loadOptions={loadOptions}
            additional={additional}
          />
        ))}
      </div>
    </div>
  );
};
