import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './slices/authSlice';
import { catalogSliceReducer } from './slices/catalogSlice';
import { elementsSliceReducer } from './slices/elementsSlice';
import { expandReducer } from './slices/expandSlice';
import { feedErrorsReducer } from './slices/feedSlice/feedErrors';
import { feedSliceReducer } from './slices/feedSlice/feedSlice';
import { filterReducer } from './slices/filterSlice';
import { massActionsReducer } from './slices/massActionsSlice';
import { pointByReducer } from './slices/pointBySlice';
import { rubricReducer } from './slices/rubricSlice';
import { setSearchSliceReducer } from './slices/search/searchSlice';
import { selectSliceReducer } from './slices/selectSlice';
import { userReducer } from './slices/userSlice';
import { authApiReducer } from '../app/api/auth/config';
import { mainApiReducer } from '../app/api/services/config';

const reducers = {
  userReducer,
  authReducer,
  pointByReducer,
  expandReducer,
  selectSliceReducer,
  rubricReducer,
  mainApiReducer,
  authApiReducer,
  catalogSliceReducer,
  filterReducer,
  massActionsReducer,
  elementsSliceReducer,
  feedSliceReducer,
  setSearchSliceReducer,
  feedErrorsReducer,
};

export const rootReducer = combineReducers<{
  userReducer;
  authReducer;
  pointByReducer;
  expandReducer;
  selectSliceReducer;
  rubricReducer;
  mainApiReducer;
  authApiReducer;
  catalogSliceReducer;
  filterReducer;
  massActionsReducer;
  elementsSliceReducer;
  feedSliceReducer;
  setSearchSliceReducer;
  feedErrorsReducer;
}>(reducers);
