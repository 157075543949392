import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEntityMerging } from '../../app/global/types';

interface ElementsState {
  elements: IEntityMerging[];
  elementsKit: any[];
}

const initialState: ElementsState = {
  elements: [],
  elementsKit: [],
};

export const elementsSlice = createSlice({
  name: 'elements',
  initialState,
  reducers: {
    setElementsSlice(state, action: PayloadAction<IEntityMerging[]>) {
      state.elements = action.payload;
    },
    setValueElementsKit(state, actions: PayloadAction<any[]>) {
      state.elementsKit = actions.payload;
    },
    clearElements(state) {
      state.elements = [];
    },
  },
});

export const { setElementsSlice, clearElements, setValueElementsKit } =
  elementsSlice.actions;
export const elementsSliceReducer = elementsSlice.reducer;
